import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import withRoot from '../withRoot';
import Helmet from 'react-helmet';
import Typography from '@material-ui/core/Typography/Typography';

const styles = theme => ({
	root: {
		textAlign: 'center',
		padding: 20,
		marginTop: 100,
		marginBottom: 100,
	},
});

class Index extends React.Component {

	render () {
		const {classes} = this.props;

		return (
			<div>

				<Helmet>
					<title itemProp="name" lang="en">RODINA</title>
					<link
						rel="canonical"
						href={`https://felononbass.com/rodina`}
					/>
				</Helmet>

				<div className={classes.root}>
					<Typography variant={'body1'} align={'center'}>5522 Ãîä. 1 Èþëÿ. 18:22<p/>.<p/>.<p/>.<p/>.<p/>.<p/>
						Êîáûëêà íåòîðîïÿñü âåðíóëà âçãëÿä â îêíî.<p/>
						Çà îêíîì áûëà Ðîäèíà. Îíà íàêîíåö-òî âåðíóëàñü. Ñïóñòÿ ãîä.<p/>
						Ïóñòü ýòî áûëî íå òî âîçâðàùåíèå î êîòîðîì îíà ìå÷òàëà... Íî âîçâðàùåíèå.<p/>
						Îíà äàâíî ñêó÷àëà ïî ýòîìó âèäó. Òžìíûé, ìðà÷íûé ìèð, ðàñïîëîæåííûé â ôèçè÷åñêè íåïîíÿòíîì
						ìåñòå.<p/>
						Ìèð, ãäå ýêîëîãèè äàæå, êàæåòñÿ, íèêîãäà íå ñóùåñòâîâàëî.<p/>
						Çà îêíîì ïðîíåñëàñü î÷åðåäíàÿ ëåòàþùàÿ ìàøèíà - îáû÷íûé òðàíñïîðò çäåñü, ïîòîìó êàê çàñòðîéêà è
						ìóñîð âíèçó íå ïîçâîëÿëè ñóùåñòâîâàòü îáû÷íîìó, êîëžñíîìó òðàíñïîðòó òàê, êàê îí "æåëàë" áû
						ýòîãî.<p/>
						Ïîíÿøà âçäîõíóâ, ïîäóìàëà î òîì, ÷òî íåïëîõî áû îäíàæäû âåðíóòüñÿ ê Ýêâåñòðèþ, êîãäà åž äåëà òóò
						áóäóò îêîí÷åíû è êîãäà ïðîõîä â Ýêâåñòðèþ áóäåò ñíîâà äîñòóïåí.<p/>
						Îíà ðàçâåðíóëàñü îò îêíà, íàïðàâèâøèñü ê åäèíñòâåííîé îòêðûòîé äâåðè â ýòîé òåìíîé êîìíàòå,
						âûõîäÿ â êîðèäîð, çàêðûâàÿ äâåðü è ïîãðóæàÿ ïîìåùåíèå â òåìíîòó.<p/>
						Åäèíñòâåííîå ÷òî åëå çàìåòíî äàâàëî ñâåò - íåîíîâàÿ âûâåñêà íà îäíîì èç çäàíèé ïîáëèçîñòè, êîèõ
						â ãîðîäå
						áûëè òûñÿ÷è.<p/>
						.<p/>
						.<p/>
						.<p/>
						.<p/>
						Êîíåö
						Êîíåö
					</Typography>
				</div>
			</div>

		);
	}
}

Index.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Index));
